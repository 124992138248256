// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as TextField from "../TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as CityStateCountry from "../../../models/CityStateCountry.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AutocompleteCitiesFieldScss from "./AutocompleteCitiesField.scss";

var css = AutocompleteCitiesFieldScss;

function AutocompleteCitiesField(props) {
  var __initialValue = props.initialValue;
  var __showSelectedValue = props.showSelectedValue;
  var __showHidden = props.showHidden;
  var __withProducts = props.withProducts;
  var __onlySearchCities = props.onlySearchCities;
  var __className = props.className;
  var onSelected = props.onSelected;
  var className = __className !== undefined ? __className : "";
  var onlySearchCities = __onlySearchCities !== undefined ? __onlySearchCities : false;
  var withProducts = __withProducts !== undefined ? __withProducts : true;
  var showHidden = __showHidden !== undefined ? __showHidden : false;
  var showSelectedValue = __showSelectedValue !== undefined ? __showSelectedValue : false;
  var initialValue = __initialValue !== undefined ? __initialValue : "";
  var search = ReactDebounce.useDebounced(500, (function (dispatch) {
          dispatch("Search");
        }));
  var initialState = React.useMemo((function () {
          return {
                  status: "Editing",
                  query: initialValue,
                  suggestions: []
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            return {
                    TAG: "UpdateWithSideEffects",
                    _0: {
                      status: "Searching",
                      query: state.query,
                      suggestions: state.suggestions
                    },
                    _1: (function (param) {
                        var dispatch = param.dispatch;
                        $$Promise.wait(Api.searchCitiesStatesCountries(param.state.query, onlySearchCities, withProducts, showHidden), (function (x) {
                                if (x.TAG === "Ok") {
                                  return dispatch({
                                              TAG: "UpdateSuggestions",
                                              _0: x._0
                                            });
                                }
                                SentryLogger.error1({
                                      rootModule: "AutocompleteCitiesField",
                                      subModulePath: /* [] */0,
                                      value: "make",
                                      fullPath: "AutocompleteCitiesField.make"
                                    }, "AutocompleteCitiesField::Error", [
                                      "Error",
                                      x._0
                                    ]);
                                dispatch({
                                      TAG: "UpdateSuggestions",
                                      _0: []
                                    });
                              }));
                      })
                  };
          }
          switch (action.TAG) {
            case "Change" :
                var query = action._0;
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          status: state.status,
                          query: query,
                          suggestions: state.suggestions
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            if (query.length > 2) {
                              return search(dispatch);
                            } else {
                              dispatch({
                                    TAG: "UpdateSuggestions",
                                    _0: []
                                  });
                              if (showSelectedValue && query === "") {
                                return onSelected(undefined);
                              } else {
                                return ;
                              }
                            }
                          })
                      };
            case "UpdateSuggestions" :
                return {
                        TAG: "Update",
                        _0: {
                          status: state.status,
                          query: state.query,
                          suggestions: state.query.length > 2 ? action._0 : []
                        }
                      };
            case "SetValue" :
                return {
                        TAG: "Update",
                        _0: {
                          status: state.status,
                          query: action._0,
                          suggestions: state.suggestions
                        }
                      };
            case "SetValueAndResetSuggestions" :
                return {
                        TAG: "Update",
                        _0: {
                          status: state.status,
                          query: action._0,
                          suggestions: []
                        }
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch({
                TAG: "SetValue",
                _0: initialValue
              });
        }), [initialValue]);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(TextField.make, {
                      id: props.id,
                      value: state.query,
                      placeholder: props.placeholder,
                      disabled: props.disabled,
                      leftIcon: props.leftIcon,
                      className: className,
                      onChange: (function ($$event) {
                          dispatch({
                                TAG: "Change",
                                _0: $$event.target.value
                              });
                        }),
                      autoComplete: "Off"
                    }),
                state.suggestions.length !== 0 ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("ul", {
                              children: Belt_Array.map(state.suggestions, (function (suggestion) {
                                      return JsxRuntime.jsx("li", {
                                                  children: CityStateCountry.toLabel(suggestion),
                                                  className: css.suggestionItem,
                                                  onClick: (function (param) {
                                                      onSelected(suggestion);
                                                      dispatch({
                                                            TAG: "SetValueAndResetSuggestions",
                                                            _0: showSelectedValue ? CityStateCountry.toLabel(suggestion) : ""
                                                          });
                                                    })
                                                }, suggestion.city + ("-" + (suggestion.state + ("-" + suggestion.country))));
                                    })),
                              className: css.suggestionsList
                            }),
                        className: css.suggestionsContainer
                      }) : null
              ]
            });
}

var make = AutocompleteCitiesField;

var $$default = AutocompleteCitiesField;

export {
  css ,
  make ,
  $$default as default,
}
/* css Not a pure module */
