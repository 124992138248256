// extracted by mini-css-extract-plugin
export var backToListView = "CreateColocationRfpProjectLocations_MapWithLocations__backToListView__xVZHe";
export var buttons = "CreateColocationRfpProjectLocations_MapWithLocations__buttons__Xbrj8";
export var column = "CreateColocationRfpProjectLocations_MapWithLocations__column__lehEs";
export var container = "CreateColocationRfpProjectLocations_MapWithLocations__container__irjcu";
export var empty = "CreateColocationRfpProjectLocations_MapWithLocations__empty__AyYXm";
export var filters = "CreateColocationRfpProjectLocations_MapWithLocations__filters__ddAeU";
export var filtersContainer = "CreateColocationRfpProjectLocations_MapWithLocations__filtersContainer__E3DBe";
export var locations = "CreateColocationRfpProjectLocations_MapWithLocations__locations__fbSqb";
export var mapViewBtn = "CreateColocationRfpProjectLocations_MapWithLocations__mapViewBtn__gQwW7";
export var mapWrapper = "CreateColocationRfpProjectLocations_MapWithLocations__mapWrapper__IYR25";
export var row = "CreateColocationRfpProjectLocations_MapWithLocations__row__AuZ1G";
export var typeCity = "CreateColocationRfpProjectLocations_MapWithLocations__typeCity__PyqYA";