// extracted by mini-css-extract-plugin
export var blockBody = "CreateColocationRfpProjectLocations_SelectLocationList__blockBody__zskEp";
export var blockHeader = "CreateColocationRfpProjectLocations_SelectLocationList__blockHeader__JRNdA";
export var column = "CreateColocationRfpProjectLocations_SelectLocationList__column__ajo5h";
export var disabledBlock = "CreateColocationRfpProjectLocations_SelectLocationList__disabledBlock__MszWy";
export var flip = "CreateColocationRfpProjectLocations_SelectLocationList__flip__R7_CP";
export var icon = "CreateColocationRfpProjectLocations_SelectLocationList__icon__QBZcn";
export var itemWrapper = "CreateColocationRfpProjectLocations_SelectLocationList__itemWrapper__DfXJQ";
export var label = "CreateColocationRfpProjectLocations_SelectLocationList__label__ex15c";
export var locationCheckbox = "CreateColocationRfpProjectLocations_SelectLocationList__locationCheckbox__Q0Ugn";
export var locationImage = "CreateColocationRfpProjectLocations_SelectLocationList__locationImage__PIu9q";
export var locationItem = "CreateColocationRfpProjectLocations_SelectLocationList__locationItem___ZScO";
export var locationsCount = "CreateColocationRfpProjectLocations_SelectLocationList__locationsCount__Yd2LN";
export var row = "CreateColocationRfpProjectLocations_SelectLocationList__row__pedCh";
export var selectBlock = "CreateColocationRfpProjectLocations_SelectLocationList__selectBlock__yFyUU";
export var someChecked = "CreateColocationRfpProjectLocations_SelectLocationList__someChecked__l7Q6u";