// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../components/Heading/H1.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Form from "../../forms/Form/Form.res.js";
import * as User from "../../../models/User.res.js";
import * as Label from "../../forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../components/Button/Button.res.js";
import * as Control from "../../components/Control/Control.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as TextField from "../../forms/TextField/TextField.res.js";
import * as SignInData from "../../../bundles/client/sign-in/SignInData.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as SignInEmailError from "../../../bundles/client/sign-in/errors/SignInEmailError.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInModalScss from "./SignInModal.scss";
import * as SignInPasswordError from "../../../bundles/client/sign-in/errors/SignInPasswordError.res.js";

var css = SignInModalScss;

function reducer(state, action) {
  if (typeof action !== "object") {
    if (action === "BeforeSubmit") {
      return {
              input: state.input,
              isSubmitted: true,
              results: state.results,
              errorMessage: state.errorMessage
            };
    } else {
      return {
              input: state.input,
              isSubmitted: false,
              results: state.results,
              errorMessage: state.errorMessage
            };
    }
  }
  switch (action.TAG) {
    case "Update" :
        return {
                input: action._0,
                isSubmitted: state.isSubmitted,
                results: state.results,
                errorMessage: state.errorMessage
              };
    case "SetErrorMessage" :
        return {
                input: state.input,
                isSubmitted: state.isSubmitted,
                results: state.results,
                errorMessage: action._0
              };
    case "SetResults" :
        return {
                input: state.input,
                isSubmitted: state.isSubmitted,
                results: action._0,
                errorMessage: state.errorMessage
              };
    
  }
}

var initialState_input = SignInData.Input.empty();

var initialState = {
  input: initialState_input,
  isSubmitted: false,
  results: undefined,
  errorMessage: ""
};

function SignInModal(props) {
  var close = props.close;
  var onSignUp = props.onSignUp;
  var setUserData = props.setUserData;
  var callback = props.callback;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var submit = function () {
    var results = SignInData.Validate.all(state.input);
    dispatch({
          TAG: "SetResults",
          _0: results
        });
    if (SignInData.Validate.valid(Belt_Option.getExn(results))) {
      return $$Promise.wait(Api.signIn(state.input), (function (x) {
                    if (x.TAG === "Ok") {
                      var res = x._0;
                      if (res.TAG === "UserObject") {
                        setUserData(User.toJs(res._0));
                        callback();
                        return close();
                      }
                      setUserData(User.toJs(res._0.user));
                      callback();
                      return close();
                    }
                    SentryLogger.error1({
                          rootModule: "SignInModal",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "SignInModal.make"
                        }, "SignIn::Error", [
                          "Error",
                          x._0
                        ]);
                    dispatch({
                          TAG: "SetErrorMessage",
                          _0: "Invalid email or password"
                        });
                  }));
    } else {
      return dispatch("AfterSubmit");
    }
  };
  var beforeSubmit = function () {
    dispatch("BeforeSubmit");
    submit();
  };
  var match$1 = state.errorMessage;
  var tmp = match$1 === "" ? null : JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx("p", {
                children: state.errorMessage
              }),
          className: css.error
        });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(H1.make, {
                      children: "Sign In"
                    }),
                JsxRuntime.jsx("div", {
                      children: props.subTitle,
                      className: css.subTitle
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        "Don't have an account? ",
                        JsxRuntime.jsx(Control.make, {
                              onClick: (function (param) {
                                  onSignUp();
                                }),
                              children: "Sign Up"
                            })
                      ],
                      className: css.toSignUp
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs(Form.make, {
                                  className: css.loginForm,
                                  onSubmit: (function (param) {
                                      beforeSubmit();
                                    }),
                                  children: [
                                    tmp,
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "sign-in-modal--email",
                                                  children: "Email"
                                                }),
                                            JsxRuntime.jsx(TextField.make, {
                                                  id: "sign-in-modal--email",
                                                  value: state.input.email,
                                                  status: Belt_Option.map(state.results, (function (x) {
                                                          if (x.email.TAG === "Ok") {
                                                            return "Valid";
                                                          } else {
                                                            return "Error";
                                                          }
                                                        })),
                                                  onChange: (function ($$event) {
                                                      var init = state.input;
                                                      dispatch({
                                                            TAG: "Update",
                                                            _0: {
                                                              email: $$event.target.value,
                                                              password: init.password
                                                            }
                                                          });
                                                    })
                                                }),
                                            JsxRuntime.jsx(SignInEmailError.make, {
                                                  results: state.results
                                                })
                                          ],
                                          className: css.fieldRow
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "sign-in-modal--password",
                                                  children: "Password"
                                                }),
                                            JsxRuntime.jsx(TextField.make, {
                                                  id: "sign-in-modal--password",
                                                  type_: "Password",
                                                  value: state.input.password,
                                                  status: Belt_Option.map(state.results, (function (x) {
                                                          if (x.password.TAG === "Ok") {
                                                            return "Valid";
                                                          } else {
                                                            return "Error";
                                                          }
                                                        })),
                                                  onChange: (function ($$event) {
                                                      var init = state.input;
                                                      dispatch({
                                                            TAG: "Update",
                                                            _0: {
                                                              email: init.email,
                                                              password: $$event.target.value
                                                            }
                                                          });
                                                    })
                                                }),
                                            JsxRuntime.jsx(SignInPasswordError.make, {
                                                  results: state.results
                                                })
                                          ],
                                          className: css.fieldRow
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Button.make, {
                                                  size: "MD",
                                                  color: "Primary",
                                                  expanded: false,
                                                  submit: true,
                                                  className: css.buttonItem,
                                                  children: "Sign In"
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("a", {
                                                        children: "Forgot password?",
                                                        href: "/users/password/new"
                                                      }),
                                                  className: css.toForgotPassword
                                                })
                                          ],
                                          className: css.signInButton
                                        })
                                  ]
                                }),
                            className: Cx.cx([
                                  css.column,
                                  css.signIn
                                ])
                          }),
                      className: css.row
                    })
              ],
              className: css.signInContent
            });
}

var Input;

var Validate;

var ValidationResult;

var make = SignInModal;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  reducer ,
  initialState ,
  make ,
}
/* css Not a pure module */
